/* ---------- Fonts ---------- */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* ---------- General ---------- */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  letter-spacing: -.03rem;
  font-weight: 500;
  overscroll-behavior: none;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.background-image-container {
  background-image: url('./images/pexels-gdtography-277628-911738.jpg'); /* Path to local image */
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
}

/* hide horizontal scroll bar */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-track {
  background: transparent;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
}
